import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Container, Head, Service } from 'Components/common';
import './highlight.scss';

export default function Template({ data }) {
  const { markdownRemark: service } = data;
  return (
    <Layout>
      <Head
        type="NewsArticle"
        headline={service.frontmatter.title}
        articleBody={service.html}
        datePublished={service.frontmatter.date}
        dateModified={
          service.frontmatter.edited
            ? service.frontmatter.edited
            : service.frontmatter.date
        }
        cover={service.frontmatter.thumbnail.childImageSharp.fluid.originalImg}
        location={service.frontmatter.path}
      >
        {service.frontmatter.title}
      </Head>
      <Service {...service} />
    </Layout>
  );
}

export const servicesQuery = graphql`
  query ServiceItemByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        edited(formatString: "MMM DD, YYYY")
        path
        title
        next
        previous
        id
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 700) {
              originalImg
            }
          }
        }
      }
    }
  }
`;
